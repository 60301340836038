<template>
  <div class="container">
    <div class="mt-4 mb-4">

      <div v-if="loading" class="d-flex justify-content-center mt-5 mb-5">
        <Preloader/>
      </div>
      <div v-else-if="conference.moderatorArticles.length">
        <h4 class="text-center mb-4">Список поданных статей</h4>

        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
            <tr>
              <th>№</th>
              <th>Название статьи</th>
<!--              <th>Авторы</th>-->
              <th>Секция</th>
              <th>Язык</th>
              <th>Статьи</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(userChance, userChanceIndex) in conference.moderatorArticles" :key="userChanceIndex">
              <td>{{userChanceIndex+1}}</td>
              <td>{{userChance.theme}}</td>
<!--              <td>{{userChance.last_name}} {{userChance.first_name}} {{userChance.middle_name}}</td>-->
              <td>{{userChance.conference_section_name}}</td>
              <td>{{userChance.language}}</td>
              <td>
                <table class="table table-sm">
                  <tbody>
                  <tr>
                    <th>Дата получения</th>
                    <th>Файлы</th>
                    <th>% плагиата (локальный)</th>
                    <th>% плагиата (strike)</th>
                    <th>Статусы</th>
                    <th>Назначение рецензентов</th>
                  </tr>
                  <tr v-for="(userArticle, userArticleIndex) in userChance.user_articles" :key="userArticleIndex">
                    <td>{{convertGraduateWorkTimestampToDate(userArticle.created_at)}}</td>
                    <td>
                      <div class="my-1">
                        <a :href="`${userArticle.article_file}`">
                          Версия {{userArticleIndex+1}}
                        </a>
                      </div>
                      <div class="my-1">
                        <a :href="`${userArticle.literature_file}`">
                          Список литературы {{userArticleIndex+1}}
                        </a>
                      </div>
                    </td>
                    <td>{{userArticle.local_plagiat||'Результата нет'}}</td>
                    <td>{{userArticle.strike_plagiat||'Результата нет'}}</td>
                    <td>
                      <div v-if="userArticle.status.length">
                        <Button label="Статусы" class="p-button-secondary p-button-text m-1"
                                @click="openStatuses(userChance.author_id, userArticle.conference_article_id)"/>
                      </div>
                    </td>
                    <td>
                      <div v-if="userArticle.reviewers.length">
                        <div v-for="(reviewer, reviewerIndex) in userArticle.reviewers" :key="reviewerIndex">
                          <div class="my-1">
                            {{reviewerIndex+1}}. {{reviewer.lastname}} {{reviewer.firstname}}
<!--                            <a href="#" @click="openUpdateReviewerDialog(reviewer.conference_review_id)">{{reviewerIndex+1}}. {{reviewer.lastname}} {{reviewer.firstname}}</a>-->
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <Button label="Назначить рецензентов" class="m-1"
                                @click="openBindReviewer(userArticle.conference_article_id)"/>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

      </div>
      <div v-else>
        <h4 class="text-center mb-4">Здесь пусто</h4>
      </div>


      <Dialog header="Назначение рецензента" v-model:visible="displayBindReviewer"
              :style="{width: '100%', maxWidth: '600px'}"
              :modal="true" :closable="false">
        <div>
          <div class="mx-1">
            <div class="my-2 row">
              <div class="col-md-3">
                Рецензент 1
              </div>
              <div class="col-md-9">
                <select class="form-control form-select" v-model="reviewer1">
                  <option v-for="(item, index) in conference.reviewers" :value="item.id" :key="index">
                    {{ item.lastname }} {{ item.firstname }}
                  </option>
                </select>
              </div>
            </div>

            <div class="my-2 row">
              <div class="col-md-3">
                Рецензент 2
              </div>
              <div class="col-md-9">
                <select class="form-control form-select" v-model="reviewer2">
                  <option v-for="(item, index) in conference.reviewers" :value="item.id" :key="index">
                    {{ item.lastname }} {{ item.firstname }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeBindReviewer"/>
          <Button label="Назначить" icon="pi pi-check" @click="bindReviewers" autofocus
                  :disabled="!reviewer1||!reviewer2||reviewer1===reviewer2"/>
        </template>
      </Dialog>


      <!-- statuses modal -->
      <Dialog header="Статусы" v-model:visible="displayStatuses"
              :style="{width: '100%', maxWidth: '600px'}"
              :modal="true" :closable="false">
        <div>
          <div v-for="(status, statusIndex) in statusesByArticle" :key="statusIndex">
            <div class="my-1">
              <div class="fw-bold">
                {{statusIndex+1}}. {{status.name}}
              </div>
              <div class="ms-2">
                Отправитель: {{status.lastname}} {{status.firstname}}
              </div>
              <div v-if="status.text" class="ms-2">
                Комментарий: {{status.text}}
              </div>
              <hr>
            </div>
          </div>
        </div>
        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text" @click="closeStatuses"/>
        </template>
      </Dialog>
      <!-- end statuses modal -->


      <!-- update reviewer dialog -->
      <Dialog header="Поменять рецензента" v-model:visible="displayUpdateReviewer" :style="{width: '100%', maxWidth: '600px'}"
              :modal="true" :closable="false">
        <div>
          <div class="mx-1">
            <div class="my-2 row">
              <div class="col-md-3">
                Рецензент
              </div>
              <div class="col-md-9">
                <select class="form-control form-select" v-model="updateReviewer">
                  <option v-for="(item, index) in conference.reviewers" :value="item.id" :key="index">
                    {{ item.lastname }} {{ item.firstname }}
                  </option>
                </select>
              </div>
            </div>

          </div>
        </div>
        <template #footer>
          <Button label="Закрыть" icon="pi pi-times" class="p-button-text"
                  @click="closeUpdateReviewerDialog"/>
          <Button label="Обновить" icon="pi pi-check" @click="putReviewer"/>
        </template>
      </Dialog>
      <!-- end update reviewer dialog -->

    </div>
  </div>
</template>


<script>
  import {mapGetters, mapActions, mapMutations, mapState} from "vuex"
  import {convertGraduateWorkTimestampToDate} from "@/utils/helpers/convertGraduateWorkTimestampToDate"

  export default {
    name: "SectionModerator",
    data() {
      return {
        loading: true,
        displayBindReviewer: false,
        articleId: 0,
        reviewer1: 0,
        reviewer2: 0,
        displayStatuses: false,
        author_id: 0,
        conference_article_id: 0,
        displayUpdateReviewer: false,
        updateReviewer: 0,
        //updateConferenceReviewId: 0
      }
    },
    computed: {
      ...mapState('conferenceModerator', ['conference']),
      statusesByArticle() {
        return this.conference.moderatorArticles
          .find(i => i.author_id == this.author_id)
          ?.user_articles.find(i => i.conference_article_id == this.conference_article_id)?.status || []
      }
    },
    methods: {
      ...mapActions('conferenceModerator', ['GET_ARTICLES', 'GET_REVIEWERS', 'POST_BIND_REVIEWERS', 'PUT_REVIEWER']),
      ...mapMutations('conferenceModerator', ['SET_UPDATE_CONFERENCE_REVIEW_ID']),
      convertGraduateWorkTimestampToDate,
      openStatuses(author_id, conference_article_id) {
        this.author_id = author_id
        this.conference_article_id = conference_article_id
        this.displayStatuses = true
      },
      closeStatuses() {
        this.displayStatuses = false
      },
      openBindReviewer(articleId) {
        this.clearBindReviewer(articleId)
        this.displayBindReviewer = true
      },
      closeBindReviewer() {
        this.displayBindReviewer = false
      },
      async bindReviewers() {
        const postData = [this.reviewer1, this.reviewer2].map(reviewer_id => ({
          reviewer_id,
          conference_article_id: this.articleId
        }))

        const status = await this.POST_BIND_REVIEWERS(postData)
        if (status) {
          await this.GET_ARTICLES()
          this.closeBindReviewer()
          this.$message({text: 'Сохранено'})
        }
      },
      clearBindReviewer(articleId) {
        this.articleId = articleId
        this.reviewer1 = 0
        this.reviewer2 = 0
      },
      openUpdateReviewerDialog(conference_review_id) {
        console.log(conference_review_id, 'conference_review_id')
        //this.updateConferenceReviewId = conference_review_id
        this.SET_UPDATE_CONFERENCE_REVIEW_ID(conference_review_id)
        this.displayUpdateReviewer = true
      },
      closeUpdateReviewerDialog() {
        this.displayUpdateReviewer = false
      },
      async putReviewer() {

        const putData = {
          reviewer_id: this.updateReviewer,
        }
        let data = await this.PUT_REVIEWER(putData)

        if (data) {
          this.displayUpdateReviewer = false
          this.updateReviewer = 0
          await this.GET_ARTICLES()
          this.$message({text: 'Обновлено'})
        }
      }
    },

    async mounted() {
      await this.GET_ARTICLES()
      await this.GET_REVIEWERS()
      this.loading = false
    }
  }
</script>

<style scoped>

</style>